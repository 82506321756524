var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseCard',{attrs:{"actions-visible":"","footer-visible":false,"caption-visible":false,"loading":_vm.loading},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"h3 font-weight-bold mb-0"},[_vm._v("CATEGORÍAS DE PRODUCTOS")])]},proxy:true},{key:"actions",fn:function(){return [_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
        resource: _vm.$data.$constants.RESOURCES.RESOURCE_CATEGORIES,
        resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
      }),expression:"{\n        resource: $data.$constants.RESOURCES.RESOURCE_CATEGORIES,\n        resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n      }"}],attrs:{"to":{ name: 'createCategory' }}},[(_vm.isSuperAdmin)?_c('b-button',{staticClass:"text-nowrap rounded-sm px-2",attrs:{"variant":"primary"}},[_vm._v(" + Añadir categoría ")]):_vm._e()],1)]},proxy:true}])},[_c('TableToolBar',{attrs:{"filters":_vm.filters,"hide-totals-button":"","hide-printer-button":"","hide-upload-button":"","resourceStore":'category'},on:{"filter-button-click":function($event){_vm.filtersVisible = !_vm.filtersVisible},"on-clear-active-filters":_vm.handleClearCategoryListFilters,"search":_vm.handleSearch}}),_c('b-row',{staticClass:"px-2 py-1 bg-table-headings",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"font-weight-bold",attrs:{"cols":"auto"}},[_vm._v(" CATEGORÍA ")])],1),_c('Tree',{ref:"category-tree",attrs:{"indent":50,"draggable":_vm.isSuperAdmin,"dragging-node-position-mode":"mouse"},on:{"drop":_vm.handleDropCategory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var node = ref.node;
      var tree = ref.tree;
      var path = ref.path;
return [_c('b-row',{staticClass:"p-2 border-0 cursor-move category-tree-node",attrs:{"no-gutters":"","align-v":"center","align-h":"between"}},[_c('b-col',{staticClass:"cursor-pointer",attrs:{"cols":"auto"}},[_c('b-link',{directives:[{name:"show",rawName:"v-show",value:(node.children && node.children.length > 0),expression:"node.children && node.children.length > 0"}],staticClass:"pr-1",on:{"click":function($event){return tree.toggleFold(node, path)}}},[(node.$folded)?_c('FeatherIcon',{attrs:{"icon":"ChevronDownIcon"}}):_c('FeatherIcon',{attrs:{"icon":"ChevronUpIcon"}})],1),_c('span',{class:{
            'category-tree-node-name': !node.children ||node.children.length === 0,
            'cursor-default': !node.children ||node.children.length === 0,
          },on:{"click":function($event){return tree.toggleFold(node, path)}}},[_vm._v(" "+_vm._s(node.name)+" ")])],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('span',[_c('b-link',{staticClass:"pr-1 d-inline-block text-indigo",attrs:{"to":{ name: 'viewCategory', params: { id: node.id} }}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Ver","icon":"EyeIcon","size":"18"}})],1),(_vm.isSuperAdmin)?_c('b-link',{directives:[{name:"access",rawName:"v-access",value:({
              resource: _vm.$data.$constants.RESOURCES.RESOURCE_CATEGORIES,
              resourceAction: _vm.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }),expression:"{\n              resource: $data.$constants.RESOURCES.RESOURCE_CATEGORIES,\n              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL\n            }"}],staticClass:"d-inline-block text-danger",on:{"click":function($event){return _vm.deleteCategory(node)}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Eliminar","icon":"TrashIcon","size":"18"}})],1):_vm._e()],1)])],1)]}}]),model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}}),_c('CategoryListFilters',{ref:"category-list-filters",on:{"on-submit-filters":_vm.handleSubmitFilters},model:{value:(_vm.filtersVisible),callback:function ($$v) {_vm.filtersVisible=$$v},expression:"filtersVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }