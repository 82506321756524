<template>
  <b-sidebar
    v-model="model"
    right
    backdrop
    bg-variant="white"
  >
    <template #header>
      <b-container
        fluid
        class="p-0 text-dark-gray bg-light-gray"
      >
        <b-row
          align-v="center"
          align-h="between"
          class="p-1"
        >
          <b-col cols="auto">
            Filtrar
          </b-col>
          <b-col cols="auto">
            <b-link
              class="text-dark-gray"
              @click="model = false"
            >
              <feather-icon
                icon="XIcon"
                size="20"
              />
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <div class="px-1 py-2">
      <b-form
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col sm="12">
            <b-form-group
              label="Nombre categoría"
              label-for="category-name"
            >
              <b-form-input
                id="category-name"
                v-model="form.name"
                name="category-name"
                placeholder="Nombre categoría"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Grupo de categorías"
              label-for="category-group"
            >
              <CategoryGroupSelect
                id="category-group"
                v-model="form.category_group"
                placeholder="Grupo de categorías"
              />
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-form-group
              label="Categoría padre"
              label-for="category-parent"
            >
              <CategorySelect
                id="category-parent"
                v-model="form.parent"
                placeholder="Categoría padre"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
              class="py-1 px-1 rounded mr-1"
            >
              Buscar
            </b-button>

            <b-button
              variant="outline-primary"
              class="py-1 px-1 rounded"
              @click="model = !model"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-sidebar>
</template>

<script>
import CategoryGroupSelect from '@/components/category/select/CategoryGroupSelect.vue'
import CategorySelect from '@/components/category/select/CategorySelect.vue'

export default {
  name: 'CategoryListFilters',
  components: { CategorySelect, CategoryGroupSelect },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: null,
        parent: null,
        categoryGroup: null,
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('on-submit-filters', { ...this.form })
    },
    clearFilters() {
      this.form = {
        name: null,
        parent: null,
        category_group: null,
      }
    },
  },
}
</script>

<style scoped>

</style>
