<template>
  <BaseCard
    actions-visible
    :footer-visible="false"
    :caption-visible="false"
    :loading="loading"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0">CATEGORÍAS DE PRODUCTOS</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_CATEGORIES,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        :to="{ name: 'createCategory' }"
      >
        <b-button v-if="isSuperAdmin"
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir categoría
        </b-button>
      </b-link>
    </template>
    <TableToolBar
      :filters="filters"
      hide-totals-button
      hide-printer-button
      hide-upload-button
      :resourceStore="'category'"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearCategoryListFilters"
      @search="handleSearch"
    />
    <b-row
      class="px-2 py-1 bg-table-headings"
      no-gutters
    >
      <b-col
        cols="auto"
        class="font-weight-bold"
      >
        CATEGORÍA
      </b-col>
    </b-row>
    <Tree
      ref="category-tree"
      v-slot="{ node, tree, path }"
      v-model="categories"
      :indent="50"
      :draggable="isSuperAdmin"
      dragging-node-position-mode="mouse"
      @drop="handleDropCategory"
    >
      <b-row
        no-gutters
        class="p-2 border-0 cursor-move category-tree-node"
        align-v="center"
        align-h="between"
      >
        <b-col
          cols="auto"
          class="cursor-pointer"
        >
          <b-link
            v-show="node.children && node.children.length > 0"
            class="pr-1"
            @click="tree.toggleFold(node, path)"
          >
            <FeatherIcon
              v-if="node.$folded"
              icon="ChevronDownIcon"
            />
            <FeatherIcon
              v-else
              icon="ChevronUpIcon"
            />
          </b-link>
          <span
            :class="{
              'category-tree-node-name': !node.children ||node.children.length === 0,
              'cursor-default': !node.children ||node.children.length === 0,
            }"
            @click="tree.toggleFold(node, path)"
          >
            {{ node.name }}
          </span>
        </b-col>
        <b-col cols="auto">
          <span>
            <b-link
              :to="{ name: 'viewCategory', params: { id: node.id} }"
              class="pr-1 d-inline-block text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Ver"
                icon="EyeIcon"
                size="18"
              />
            </b-link>
            <b-link
              v-access="{
                resource: $data.$constants.RESOURCES.RESOURCE_CATEGORIES,
                resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
              }"
              class="d-inline-block text-danger"
              v-if="isSuperAdmin"
              @click="deleteCategory(node)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </b-link>
          </span>
        </b-col>
      </b-row>
    </Tree>
    <CategoryListFilters
      ref="category-list-filters"
      v-model="filtersVisible"
      @on-submit-filters="handleSubmitFilters"
    />
  </BaseCard>
</template>

<script>
import CategoriesApi from '@/api/categories-api'
import { Tree, Fold, Draggable } from 'he-tree-vue'
import CategoryListFilters from '@/components/category/filters/CategoryListFilters.vue'
import TableToolBar from '@/components/ui/table/TableToolBar.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BaseCard,
    TableToolBar,
    CategoryListFilters,
    Tree: Tree.mixPlugins([Fold, Draggable]),
  },
  data() {
    return {
      searchTerm: '',
      searchTimeout: null,
      searchInputVisible: false,
      categories: [],
      loading: false,
      filtersVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'category/getFilters',
      search: 'category/getSearch',
    }),
    categoryTreeReference() {
      return this.$refs['category-tree']
    },
    isSuperAdmin() {
      return JSON.parse(localStorage.getItem('userData')).roles[0].name === 'super_admin'
    },
  },
  mounted() {
    this.chargeData()
  },
  methods: {
    async chargeData() {
      try {
        this.loading = true
        const response = await CategoriesApi.list({ filters: this.filters, search: this.search })
        this.categories = this.formatCategoryTree(response.data)
      } finally {
        this.loading = false
      }
    },
    formatCategoryTree(treeResponse) {
      if (!treeResponse || treeResponse.length === 0) {
        return []
      }

      return treeResponse.reduce((result, category) => result.concat(this.formatCategory(category)), [])
    },
    formatCategory(category) {
      if (!category.chields || category.chields.length === 0) {
        return { ...category, text: category.name }
      }

      const currentCategory = { ...category, text: category.name, children: [],  $folded:true }
      currentCategory.chields.forEach(categoryChild => {
        currentCategory.children.push(this.formatCategory(categoryChild))
      })

      return currentCategory
    },
    handleSearch() {
      this.chargeData()
    },
    handleSubmitFilters(filters) {
      this.$store.commit('category/setFilters', filters)
      this.chargeData()
    },
    handleClearCategoryListFilters() {
      this.$store.commit('category/setSearch', null)
      this.$store.commit('category/setFilters', {})
      this.$refs['category-list-filters'].clearFilters()
      this.chargeData()
    },
    async handleDropCategory(e) {
      const parentNode = this.categoryTreeReference.getNodeParentByPath(e.targetPath)
      const childrenNodes = parentNode ? parentNode.children : this.categories
      const currentNode = e.dragNode
      this.loading = true

      try {
        await CategoriesApi.setCategoryParent(currentNode.id, parentNode?.id || '')
        await CategoriesApi.setCategoryOrder(childrenNodes.map(node => node.id))
      } finally {
        this.loading = false
      }
    },
    async deleteCategory(category) {
      if (!category) {
        return
      }
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${category.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await CategoriesApi.delete(category.id)
        await this.chargeData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.category-tree-node:hover {
  background-color: #f3f2f7;
}

.category-tree-node-name {
  padding-left: 35px;
}
</style>
